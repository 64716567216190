<template>
	<div />
</template>

<script>
import { AGENCY_REPLICATED_SITE_NAME } from '@/settings/Replicated';

export default {
	name: 'ReplicatedSite',
	created() {
		const { replicatedId } = this.$route.params;
		const { option } = this.$route.query;

		if (replicatedId.toLowerCase() === AGENCY_REPLICATED_SITE_NAME.toLowerCase()) {
			this.$router.push({ name: 'AgencyStore' });
			return;
		}

		if (typeof replicatedId === 'string' && replicatedId.trim() !== '') {
			this.$replicated.siteOn(this.$route.params.replicatedId);
		}
		if (typeof option === 'string' && option.trim() !== '') {
			this.$replicated.siteReplicatedPageOn(this.$route.query.option);
		}
		this.$router.push({ name: 'Main', query: this.$route.query });
	},
};
</script>
